// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-course-index-tsx": () => import("./../../../src/pages/course/index.tsx" /* webpackChunkName: "component---src-pages-course-index-tsx" */),
  "component---src-pages-course-neet-index-tsx": () => import("./../../../src/pages/course/neet/index.tsx" /* webpackChunkName: "component---src-pages-course-neet-index-tsx" */),
  "component---src-pages-course-ssc-index-tsx": () => import("./../../../src/pages/course/ssc/index.tsx" /* webpackChunkName: "component---src-pages-course-ssc-index-tsx" */),
  "component---src-pages-course-tnpsc-index-tsx": () => import("./../../../src/pages/course/tnpsc/index.tsx" /* webpackChunkName: "component---src-pages-course-tnpsc-index-tsx" */),
  "component---src-pages-course-upsc-best-infrastructure-tsx": () => import("./../../../src/pages/course/upsc/bestInfrastructure.tsx" /* webpackChunkName: "component---src-pages-course-upsc-best-infrastructure-tsx" */),
  "component---src-pages-course-upsc-classroom-course-tsx": () => import("./../../../src/pages/course/upsc/classroomCourse.tsx" /* webpackChunkName: "component---src-pages-course-upsc-classroom-course-tsx" */),
  "component---src-pages-course-upsc-index-tsx": () => import("./../../../src/pages/course/upsc/index.tsx" /* webpackChunkName: "component---src-pages-course-upsc-index-tsx" */),
  "component---src-pages-course-upsc-interview-training-tsx": () => import("./../../../src/pages/course/upsc/interviewTraining.tsx" /* webpackChunkName: "component---src-pages-course-upsc-interview-training-tsx" */),
  "component---src-pages-course-upsc-mains-test-series-tsx": () => import("./../../../src/pages/course/upsc/mainsTestSeries.tsx" /* webpackChunkName: "component---src-pages-course-upsc-mains-test-series-tsx" */),
  "component---src-pages-course-upsc-online-course-tsx": () => import("./../../../src/pages/course/upsc/onlineCourse.tsx" /* webpackChunkName: "component---src-pages-course-upsc-online-course-tsx" */),
  "component---src-pages-course-upsc-postel-course-tsx": () => import("./../../../src/pages/course/upsc/postelCourse.tsx" /* webpackChunkName: "component---src-pages-course-upsc-postel-course-tsx" */),
  "component---src-pages-course-upsc-prelims-test-series-tsx": () => import("./../../../src/pages/course/upsc/prelimsTestSeries.tsx" /* webpackChunkName: "component---src-pages-course-upsc-prelims-test-series-tsx" */),
  "component---src-pages-enroll-tsx": () => import("./../../../src/pages/enroll.tsx" /* webpackChunkName: "component---src-pages-enroll-tsx" */),
  "component---src-pages-faculty-tsx": () => import("./../../../src/pages/faculty.tsx" /* webpackChunkName: "component---src-pages-faculty-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-study-materials-books-tsx": () => import("./../../../src/pages/studyMaterials/books.tsx" /* webpackChunkName: "component---src-pages-study-materials-books-tsx" */),
  "component---src-pages-study-materials-current-affairs-tsx": () => import("./../../../src/pages/studyMaterials/currentAffairs.tsx" /* webpackChunkName: "component---src-pages-study-materials-current-affairs-tsx" */),
  "component---src-pages-study-materials-important-links-tsx": () => import("./../../../src/pages/studyMaterials/importantLinks.tsx" /* webpackChunkName: "component---src-pages-study-materials-important-links-tsx" */),
  "component---src-pages-study-materials-prev-question-papers-tsx": () => import("./../../../src/pages/studyMaterials/prevQuestionPapers.tsx" /* webpackChunkName: "component---src-pages-study-materials-prev-question-papers-tsx" */),
  "component---src-pages-study-materials-tnpsc-tsx": () => import("./../../../src/pages/studyMaterials/tnpsc.tsx" /* webpackChunkName: "component---src-pages-study-materials-tnpsc-tsx" */),
  "component---src-pages-study-materials-upsc-reference-mains-tsx": () => import("./../../../src/pages/studyMaterials/upscReferenceMains.tsx" /* webpackChunkName: "component---src-pages-study-materials-upsc-reference-mains-tsx" */),
  "component---src-pages-study-materials-upsc-reference-prelims-tsx": () => import("./../../../src/pages/studyMaterials/upscReferencePrelims.tsx" /* webpackChunkName: "component---src-pages-study-materials-upsc-reference-prelims-tsx" */),
  "component---src-pages-thank-you-answer-key-tsx": () => import("./../../../src/pages/thankYou/answerKey.tsx" /* webpackChunkName: "component---src-pages-thank-you-answer-key-tsx" */),
  "component---src-pages-thank-you-career-tsx": () => import("./../../../src/pages/thankYou/career.tsx" /* webpackChunkName: "component---src-pages-thank-you-career-tsx" */),
  "component---src-pages-thank-you-enroll-tsx": () => import("./../../../src/pages/thankYou/enroll.tsx" /* webpackChunkName: "component---src-pages-thank-you-enroll-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-pages-why-tsx": () => import("./../../../src/pages/why.tsx" /* webpackChunkName: "component---src-pages-why-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

