// import { dark } from "@theme-ui/presets"

export default {
  breakpoints: ["576px", "768px", "992px", "1200px"],
  colors: {
    primary: "#BE0800",
    secondary: "#F0E066",
    text: "#212121",
    accent: "#717171",
    highlight: "#FFF0F06A",
    background: "#fff",
    muted: "#E3E3E3",
    modes: {
      dark: {
        text: "hsl(210, 50%, 96%)",
        accent: "#8c908ce8",
        background: "#022c43",
        primary: "#ffd700",
        secondary: "#ffd700",
        highlight: "hsl(71, 82%, 35%)",
        muted: "#115173",
      },
    },
  },
  fonts: {
    body: "Open Sans, Lato, sans-serif",
    heading: "Lato, serif",
    monospace: "monospace",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  fontSizes: [
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "2rem",
    "2.5rem",
    "3.5rem",
    "4.5rem",
    "5.5rem",
    "6rem",
  ],
  space: ["0rem", "0.25rem", "0.5rem", "1rem", "2rem", "4rem", "6rem", "8rem"],
  text: {
    default: {
      color: "text",
      fontSize: 2,
      lineHeight: "body",
    },
    secondary: {
      color: "accent",
      lineHeight: "body",
      fontSize: 2,
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
    heading: {
      lineHeight: "heading",
      fontWeight: "bold",
      fontSize: 2,
    },
  },
  layout: {
    container: {
      maxWidth: [null, "1600px"],
    },
  },
  images: {
    avatar: {
      width: "120px",
      height: "120px",
    },
  },
  shadows: ["0 1px 4px rgba(26,26,67,.1)", "0 6px 10px rgba(0,0,0,.08)"],
  buttons: {
    primary: {
      "&": {
        position: "relative",
        overflow: "hidden",
        transform: "perspective(800px) translate3d(0, 0, 0)",
        transition: "transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
      },
      "&:hover": {
        transform: "perspective(800px) translate3d(0, 0, 43px)",
      },
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        pointerEvents: "none",
        backgroundImage:
          "radial-gradient(circle, #fff 10%, transparent 10.01%)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        transform: "scale(0, 0)",
        opacity: "0.3",
        transition: "0s",
      },
      "&:hover:after": {
        transform: "scale(10, 10)",
        opacity: 0,
        transition: "transform 0.5s, opacity 1s",
      },
    },
    menu: {
      color: "primary",
      padding: 0,
    },
  },
  forms: {
    label: {
      color: "#6F6F6F",
    },
    input: {
      border: "1px solid #707070",
      background: "#fff",
    },
    textarea: {
      border: "1px solid #707070",
      background: "#fff",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontSize: 2,
    },
    a: {
      color: "primary",
      cursor: "pointer",
      textTransform: "uppercase",
    },
  },
  cards: {
    primary: {
      background: "#F7F7F776",
      border: "1px solid #7070706E",
    },
    shadow1: {
      boxShadow: 0,
      transform: "perspective(1000px)",
      transition: "transform 0.3s",
    },
    shadow2: {
      boxShadow: 1,
      transform: "perspective(1000px)",
      transition: "transform 0.3s",
      // "&:hover": {
      //   transform: "perspective(1000px) translate3d(0, 0, 40px)",
      // },
    },
  },
}
